<template>
  <div class="order content-container">
    <!-- <div class="order__action" style="gap: 8px"> -->
    <!-- <div class="" style="margin: 30px 0px">
        <template v-if="order !== null">
          <a-button-group> -->
    <!-- <template
              v-if="
                currentStatusNumber === 3 &&
                  isDealer &&
                  order.payment.status === 'init'
              "
            >
              <a-button
                @click="
                  setStatusPaid({ uuid: order.uuid, type: order.payment.type })
                "
              >
                Оплачен
              </a-button>
            </template> -->

    <!--если оплачен-->
    <!-- <template v-if="order.payment.status === 'paid'">
              <a-button @click="setStatus(4)">Выполнен</a-button>
              <a-button @click="setStatus(5)">Возврат</a-button>
            </template> -->

    <!--если не оплачен-->
    <!-- <template v-if="order.payment.status === 'init'">
              <a-button
                @click="
                  setStatusPaid({ uuid: order.uuid, type: order.payment.type })
                "
              >
                Оплачен
              </a-button>
            </template> -->

    <!-- <template v-if="currentStatusNumber === 2 && isDealer">
              <a-button @click="showCorrection()">Коррекция заказа</a-button>
            </template>

            <template v-for="orderStatus in showStatusButtons">
              <a-button
                :key="orderStatus.id"
                @click="setStatus(orderStatus.id)"
              >
                {{ orderStatus.name }}
              </a-button>
            </template> -->

    <!-- <template v-if="currentStatusNumber === 3 && isClient">
              <a-button @click="onRefund()">
                {{ refundedProducts.length ? "Подтвердить" : "Полный возврат" }}
              </a-button>
            </template> -->
    <!-- </a-button-group>
        </template>
      </div> -->

    <!-- <div v-if="showStatusButtons.length" class="order-upload">
        <div style="margin: 15px">
          <input multiple="multiple" ref="file" type="file" />
        </div>

        <div style="margin: 15px">
          <a-textarea
            :auto-size="{ minRows: 2, maxRows: 6 }"
            placeholder="комментарий..."
            v-model="comment"
          />
        </div>
      </div> -->
    <!-- </div> -->

    <div class="order__content">
      <h1 class="order__title">Заказ</h1>
      <div class="order__item">
        <a-button class="excelBtn">Выгрузить в excel</a-button>
        <h2>Данные заказа</h2>
        <ul class="order__list">
          <li class="order__list-item">
            <span>Уникальный ID</span>
            <span>test</span>
          </li>
          <li class="order__list-item">
            <span>Дата</span>
            <span>test</span>
          </li>
          <li class="order__list-item">
            <span>Бренд</span>
            <span>test</span>
          </li>
        </ul>
      </div>
      <div class="order__item">
        <h2>Клиент</h2>
        <ul class="order__list">
          <li class="order__list-item">
            <span>Наименование дилера</span>
            <span>test</span>
          </li>
          <li class="order__list-item">
            <span>Код дилера</span>
            <span>test</span>
          </li>
          <li class="order__list-item">
            <span>E-mail</span>
            <span>test</span>
          </li>
          <li class="order__list-item">
            <span>Телефон</span>
            <span>test</span>
          </li>
          <li class="order__list-item">
            <span>Получатель</span>
            <span>test</span>
          </li>
          <li class="order__list-item">
            <span>Адрес доставки</span>
            <span>test</span>
          </li>
        </ul>
      </div>
      <div class="order__item">
        <h2>Товары</h2>
        <a-list
          :data-source="orderBasketProductsOwner"
          item-layout="horizontal"
        >
          <a-list-item
            slot="renderItem"
            slot-scope="item"
            v-bind:class="[item.isCorrected ? 'corrected' : '']"
          >
            <div class="ant-list-item-meta">
              <div class="ant-list-item-meta-content">
                <h4 class="ant-list-item-meta-title">
                  <span>Имя продукта</span>
                </h4>
                <div class="ant-list-item-meta-description">
                  <!--                  <template v-if="item.isCorrected">-->
                  <!--                    <strike>Количество:</strike>-->
                  <!--                    <span>-->
                  <!--                      <strike>{{ item.countOld }}</strike>-->
                  <!--                      <br />-->
                  <!--                      <strong>-->
                  <!--                        Количество после коррекции: {{ item.countNew }}-->
                  <!--                      </strong>-->
                  <!--                    </span>-->
                  <!--                  </template>-->
                  <template>Количество после коррекции: 0</template>
                  <br />
                  Код: 123
                </div>
              </div>
              <div class="actionBtns">
                <a-button type="primary">Редактировать</a-button>
                <a-button type="primary">Удалить</a-button>
              </div>
            </div>
            <a-space>
              <div class="orderCheckboxBlock">
                <p>Наличие</p>
                <a-checkbox />
              </div>
              <div class="orderCheckboxBlock">
                <p>Транзит</p>
                <a-checkbox />
              </div>
            </a-space>
          </a-list-item>
        </a-list>
        <div class="actionForItems">
          <a-button type="primary">Добавить</a-button>
          <a-button type="primary">Сохранить</a-button>
        </div>
      </div>
      <div class="order__item">
        <h2>Платежные данные</h2>
        <ul class="order__list">
          <li class="order__list-item">
            <span>Сумма</span>
            <span>test</span>
          </li>
          <!--          <li class="order__list-item">-->
          <!--            <span>Тип оплаты</span>-->
          <!--            <span>-->
          <!--              {{-->
          <!--                order.payment.type === "cash"-->
          <!--                  ? "Наличный расчёт"-->
          <!--                  : "Безналичный расчёт"-->
          <!--              }}-->
          <!--            </span>-->
          <!--          </li>-->
          <!--          <li class="order__list-item">-->
          <!--            <span>Статус оплаты</span>-->
          <!--            <span>-->
          <!--              {{ order.payment.status === "payed" ? "Оплачен" : "Не оплачен" }}-->
          <!--            </span>-->
          <!--          </li>-->
        </ul>
      </div>
      <div class="order__item">
        <h2>Статус заказа</h2>
        <ul class="order__list">
          <li class="order__list-item">
            <span>Статус</span>
            <span>test</span>
          </li>
          <li class="order__list-item">
            <span>Комментарий</span>
            <span>test</span>
          </li>
        </ul>
      </div>

      <div class="order__footer" style="gap: 8px">
        <h3>История</h3>
        <a-collapse class="order-details-collapse" v-model="activeCollapseKey">
          <a-collapse-panel header="Движение заказа" key="1">
            <OrderStatusHistory />
          </a-collapse-panel>
          <a-collapse-panel header="Коррекции" key="2">
            <OrderCorrectionHistory />
          </a-collapse-panel>
          <a-collapse-panel header="Возвраты" key="3">
            <OrderRefundHistory />
          </a-collapse-panel>
        </a-collapse>

        <div class="">
          <a-button @click="$router.push('/orders')" type="link">
            К списку заказов
          </a-button>
        </div>
      </div>
    </div>
    <!-- /order__content -->
    <!-- <a-modal
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      :width="800"
      @ok="sendDecline"
      cancel-text="Отмена"
      ok-text="Отправить"
      title="Отмена заказа"
      v-model="showDeclineModal"
    >
      <div>
        <a-textarea
          placeholder="Введите причину отмены"
          required
          v-model="declineComment"
        ></a-textarea>
      </div>
    </a-modal>
    <a-modal
      :confirm-loading="confirmLoading"
      @ok="sendRefund"
      cancel-text="Отмена"
      ok-text="Оформить"
      title="Возврат товара"
      v-model="refundVisible"
    >
      <a-space>
        Возращаемое количество
        <a-input-number v-model="refundCount" />
      </a-space>

      <a-space>
        <div class="order-upload">
          <div style="margin: 15px">
            <input multiple="multiple" ref="refundFile" type="file" />
          </div>

          <div style="margin: 15px">
            <a-textarea
              :auto-size="{ minRows: 2, maxRows: 6 }"
              placeholder="комментарий..."
              v-model="refundComment"
            />
          </div>
        </div>
      </a-space>
    </a-modal> -->
    <!-- <OrderDetailsCorrection
      :order="order"
      @send="sendCorrection"
    ></OrderDetailsCorrection> -->
  </div>
</template>
<script>
import OrderService from "@/services/api/v1/OrderService"
import { mapActions, mapGetters, mapMutations } from "vuex"
import moment from "moment"
import OrderRefundHistory from "@/components/orders/OrderRefundHistory"
// import OrderDetailsCorrection from "@/components/orders/OrderDetailsCorrection"
import OrderCorrectionHistory from "@/components/orders/OrderCorrectionHistory"
import OrderStatusHistory from "@/components/orders/OrderStatusHistory"
import { message } from "ant-design-vue"

export default {
  name: "OwnerDetailOrder",
  components: {
    OrderRefundHistory,
    //  OrderDetailsCorrection,
    OrderCorrectionHistory,
    OrderStatusHistory,
  },
  filters: {
    date(value) {
      return moment(value).format("DD.MM.YYYY HH:mm")
    },
  },
  data: () => ({
    order: null,
    activeCollapseKey: ["1"],
    showDeclineModal: false,
    declineComment: "",
    confirmLoading: false,
    refundVisible: false,
    refundCount: 0,
    refundProductNumber: 0,
    refundOrderUuid: 0,
    refundComment: "",
    refundedProducts: [],
    previewVisible: false,
    previewImage: "",
    comment: "",
    orderBasketProductsOwner: [
      { id: 24, number: 1, count: 1, calculate_price: 45.36 },
      { id: 25, number: 2, count: 1, calculate_price: 282.79 },
    ],
  }),
  computed: {
    ...mapGetters({
      isPageLoading: "auth/isPageLoading",
      orders: "orders/orders",
      isClient: "auth/isBuyer",
      isDealer: "auth/isDealer",
      isOwner: "auth/isOwner",
      isAdmin: "auth/isAdmin",
      orderStatuses: "orders/orderStatuses",
      statusButtonsMatrix: "orders/statusButtonsMatrix",
      orderCorrections: "orders/orderCorrections",
      refundItems: "orders/refundItems",
      statusMethods: "orders/statusMethods",
    }),
    currentStatusNumber() {
      return this.order === null ? null : this.order.current_status.status_id
    },
    showStatusButtons() {
      if (!this.order || !this.currentStatusNumber || !this.statusButtonsMatrix)
        return []

      let role = null
      if (this.isDealer) {
        role = "dealer"
      } else if (this.isClient) {
        role = "client"
      } else if (this.isOwner) {
        role = "owner"
      } else if (this.isAdmin) {
        role = "admin"
      }
      if (role === null) return []

      let clientType = "legal" //тип покупателя юрлицо или физ лицо

      const OrderStatusIds =
        this.statusButtonsMatrix[clientType][role][this.currentStatusNumber]

      if (OrderStatusIds === undefined) return []

      return this.orderStatuses.filter(item =>
        OrderStatusIds["ids"].includes(item.number)
      )
    },
    orderCorrectionId() {
      return this.order?.current_status.info.correction_id
        ? this.order.current_status.info.correction_id
        : null
    },
    orderCorrectionsCurrent() {
      return this.orderCorrections.filter(
        item =>
          parseInt(item.correction_id) === parseInt(this.orderCorrectionId)
      )
    },
    // Подсветка скорректированных и возвращенных товаров
    orderBasketProducts() {
      if (!this.order) return []

      let vm = this

      let setCorrectedProd = function (item, correctedItem) {
        if (correctedItem) {
          item.isCorrected = true
          item.countOld = item.count
          item.countNew = correctedItem.count
        }
        return item
      }

      return this.order.basket.products.map(item => {
        // corrected prods
        item = setCorrectedProd(
          item,
          vm.orderCorrectionsCurrent.find(
            itemSub => parseInt(itemSub.number) === parseInt(item.number)
          )
        )

        // refunded prods
        item = setCorrectedProd(
          item,
          vm.refundItems.find(
            itemSub => parseInt(itemSub.basket_number) === parseInt(item.number)
          )
        )

        return item
      })
    },
    orderPrice() {
      let totalPrice = 0
      this.order.basket.products.map(item => {
        totalPrice += item.count * item.calculate_price
      })
      return totalPrice
    },
  },
  watch: {
    orders() {
      this.getOrderData()
    },
    activeCollapseKey(key) {
      console.log(key)
    },
  },
  mounted() {
    this.getOrderData()
  },
  methods: {
    async sendRefund() {
      this.confirmLoading = true

      let formData = new FormData()

      formData.append("number", this.refundProductNumber)
      formData.append("count", this.refundCount)
      formData.append("comment", this.refundComment)

      for (let i = 0; i < this.$refs.refundFile.files.length; i++) {
        let file = this.$refs.refundFile.files[i]
        const fileName = "files[file_" + i + "]"
        formData.append(fileName, file)
      }

      try {
        // eslint-disable-next-line no-unused-vars
        const resp = await this.refundProduct({
          orderUuid: this.refundOrderUuid,
          formData: formData,
        })

        this.refundedProducts.push(this.refundProductNumber)
        this.$message.success("Товар возвращён")
        this.getOrderData()
      } catch (error) {
        if (error.response) {
          message.error(error.response.data.message)
        }
      }

      this.resetRefundData()
    },
    resetRefundData() {
      this.confirmLoading = false
      this.refundVisible = false
      this.refundProductNumber = 0
      this.refundCount = 0
      this.refundComment = ""
    },
    setRefundData(orderId, productNumber, count) {
      this.refundCount = count
      this.refundOrderUuid = orderId
      this.refundProductNumber = productNumber
      this.refundVisible = true
    },
    async onRefund() {
      if (!this.refundedProducts.length) {
        this.$confirm({
          title: "Вы действительно хотите сделать полный возврат?",
          content: "",
          okText: "Да",
          okType: "danger",
          cancelText: "Нет",
          onOk() {
            this.setStatus(5)
          },
          onCancel() {},
        })
      }

      if (this.refundedProducts.length) this.setStatus(5)
    },
    async sendDecline() {
      this.confirmLoading = true
      const srv = new OrderService()
      const response = await srv.declineOrder(this.order, this.declineComment)
      this.order.status = response.data.status
      this.order.status_comment = response.data.status_comment
      this.confirmLoading = false
      this.showDeclineModal = false
    },
    async getOrderData() {
      this.setPageLoading(true)
      const { id = null } = this.$route.params
      if (id) {
        const order = this.orders.find(item => item.uuid === id)
        if (order) {
          this.order = order
        } else {
          this.order = await this.getOrder(id)
          await this.fetchOrderStatuses()
          //await this.fetchCurrentOrderStatuses(id)
          await this.fetchOrderCorrections(id)
          await this.fetchRefundProducts(id)
        }
      }

      this.setPageLoading(false)
    },
    prepareFormData() {
      let formData = new FormData()

      formData.append("orderUuid", this.order.uuid)
      formData.append("comment", this.comment)

      for (let i = 0; i < this.$refs.file.files.length; i++) {
        let file = this.$refs.file.files[i]
        const fileName = "files[file_" + i + "]"
        formData.append(fileName, file)
      }

      return formData
    },
    async setStatus(orderStatusId) {
      if (this.order == null) return false

      const formData = this.prepareFormData()

      // Validations
      if (
        this.statusMethods[orderStatusId]?.needComment &&
        formData.get("comment") === ""
      ) {
        this.$message.error("Введите комментарий")
        return false
      }
      // @Validations

      const method = this.statusMethods[orderStatusId]["m"]

      try {
        await this[method](formData)
      } catch (error) {
        if (error.response) {
          message.error(error.response.data.message)
        }
      }

      this.getOrderData()
    },
    async setStatusPaid(data) {
      await this.setOrderPaid(data)
      this.getOrderData()
    },
    showCorrection() {
      this.setCorrectionModalVisible(true)
    },
    hideCorrection() {
      this.setCorrectionModalVisible(false)
    },
    sendCorrection() {
      this.hideCorrection()
      this.getOrderData()
    },
    ...mapMutations({
      setCorrectionModalVisible: "orders/SET_CORRECTION_MODAL_VISIBLE",
      setPageLoading: "auth/SET_PAGE_LOADING",
    }),
    ...mapActions({
      getOrder: "orders/getOrder",
      refundProduct: "orders/refundProduct",
      fetchOrderStatuses: "orders/getOrderStatuses",
      fetchCurrentOrderStatuses: "orders/getCurrentOrderStatuses",
      fetchOrderCorrections: "orders/getOrderCorrections",
      fetchRefundProducts: "orders/getRefundProducts",
      setStatusInWork: "orders/setStatusInWork",
      setStatusConfirmed: "orders/setStatusConfirmed",
      setStatusCompleted: "orders/setStatusCompleted",
      setStatusRefund: "orders/setStatusRefund",
      setStatusConsideration: "orders/setStatusConsideration",
      setStatusRefundConfirmed: "orders/setStatusRefundConfirmed",
      setStatusRefundCanceled: "orders/setStatusRefundCanceled",
      setStatusCorrection: "orders/setStatusCorrection",
      setStatusCancelDS: "orders/setStatusCancelDS",
      setStatusCorrectionCompleted: "orders/setStatusCorrectionCompleted",
      setStatusCancel: "orders/setStatusCancel",
      setOrderPaid: "orders/setOrderPaid",
    }),
  },
}
</script>
<style lang="scss">
$wrapper-width: 600px;

.order {
  background-color: white;
  &__title {
    font-size: 32px;
    text-align: center;
    line-height: 1.3;
  }

  h2 {
    line-height: 1.3;
    margin-bottom: 0;
    margin-top: 2rem;
    font-weight: 900;
    font-size: 18px;
  }

  &__item {
    margin: 0 10px;
    @media (min-width: 1400px) {
      max-width: 600px;
      margin: 0 auto;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__list-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 7px 0;
    border-bottom: 1px solid #ececec;
  }

  &__action {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__footer {
    display: flex;
    width: 100%;
    margin-top: 50px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .order-upload {
    width: 100%;
    max-width: $wrapper-width;
  }

  /* you can make up upload button and sample style by using stylesheets */
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }

  .order-details-refund {
    width: 100%;
    max-width: $wrapper-width;
  }

  .order-details-collapse {
    width: 100%;
    max-width: $wrapper-width;
  }

  .correction-details {
    margin-top: 50px;
    margin-bottom: 10px;
  }

  .corrected {
    background: #f3ff4d36;
    padding: 10px;
  }

  .orderCheckboxBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ant-list-item {
    align-items: start;
  }
  .ant-list-item-meta {
    flex-direction: column;
  }

  .actionBtns {
    margin-top: 20px;
    display: flex;
    gap: 10px;
  }

  .actionForItems {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }

  .excelBtn {
    display: block;
    margin-left: auto;
  }
}
</style>
