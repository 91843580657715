<template>
  <div class="order-correction-history">
    <a-table
      :columns="columns"
      :data-source="orderCorrections"
      :rowKey="row => row.id"
    >
      <span slot="updated_at" slot-scope="item">
        {{ item.updated_at | date }}
      </span>
    </a-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import moment from "moment"

export default {
  name: "OrderCorrectionHistory",
  data: () => ({
    columns: [
      {
        title: "Наименование",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Кол-во",
        dataIndex: "count",
        key: "count",
      },
      {
        title: "Обновлен",
        key: "updated_at",
        scopedSlots: { customRender: "updated_at" },
      },
    ],
  }),
  computed: {
    ...mapGetters({
      orderCorrections: "orders/orderCorrections",
    }),
  },
  filters: {
    date(value) {
      return moment(value).format("DD.MM.YYYY HH:mm")
    },
  },
}
</script>

<style lang="scss">
.order-correction-history {
  width: 100%;
}
</style>
