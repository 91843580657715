<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="items"
      :rowKey="row => row.id"
      :customRow="customRow"
    >
      <span slot="updated_at" slot-scope="item">
        {{ item.updated_at | date }}
      </span>

      <span slot="media" slot-scope="item">
        <template v-for="media in item.media">
          <a :key="media.id" :href="media.original_url" target="_blank">
            [{{ media.mime_type }}]
          </a>
        </template>
      </span>

      <span slot="actions" slot-scope="item">
        <a-button @click="showRefundDetails(item)">Открыть</a-button>
      </span>
    </a-table>

    <!--Modal-->
    <a-modal
      :visible="visible"
      title=""
      @ok="handleOk"
      centered
      :maskClosable="false"
      :footer="null"
      @cancel="hideModal"
    >
      <template v-if="item">
        <h3>Комментарий</h3>
        <p>{{ item.comment }}</p>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import moment from "moment"

export default {
  name: "OrderRefundHistory",
  props: {},
  data: () => ({
    visible: false,
    item: null,
    columns: [
      {
        title: "Товар",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Кол-во",
        dataIndex: "count",
        key: "count",
      },
      {
        title: "Файлы",
        key: "media",
        scopedSlots: { customRender: "media" },
      },
      {
        title: "Обновлен",
        key: "updated_at",
        scopedSlots: { customRender: "updated_at" },
      },
    ],
  }),
  computed: {
    ...mapGetters({
      items: "orders/refundItems",
      order: "orders/order",
      isDealer: "auth/isDealer",
    }),
  },
  methods: {
    edit(id) {
      console.log(id)
    },
    handleOk() {
      console.log("handleOk")
    },
    showRefundDetails(item) {
      this.item = item
      this.visible = true
    },
    hideModal() {
      this.visible = false
      this.item = null
    },
    customRow(record) {
      return {
        on: {
          click: () => {
            this.item = record
            this.visible = true
          },
        },
      }
    },
  },
  filters: {
    date(value) {
      return moment(value).format("DD.MM.YYYY HH:mm")
    },
  },
}
</script>

<style lang="scss"></style>
